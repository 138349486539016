import { UserLocation } from '../types/Locations';
import { UserPreferences } from '../types/UserPreferences';

export const inferPreferencesForUserLocation = (
  location: UserLocation,
): Partial<UserPreferences> => {
  if (location.country === 'GB')
    return {
      currency: 'GBP',
      unitOfMeasurement: 'CM',
      countryCode: location.country,
    };

  if (location.continent === 'EU')
    return {
      currency: 'EUR',
      unitOfMeasurement: 'CM',
      countryCode: location.country,
    };

  return {
    currency: 'USD',
    unitOfMeasurement: 'IN',
    countryCode: location.country,
  };
};
