import { ApolloClient } from '@apollo/client';

import { GET_DRAW_ENTRY_INTENT } from '../../../apollo/api/queries';
import {
  CreateDrawEntryIntentDocument,
  GeneratedCreateDrawEntryIntentMutation,
  GeneratedCreateDrawEntryIntentMutationVariables,
  GeneratedGetDrawByProductIdQuery,
  GeneratedGetDrawByProductIdQueryVariables,
  GeneratedGetDrawEntryIntentQuery,
  GeneratedGetDrawEntryIntentQueryVariables,
  GeneratedOrderItem,
  GeneratedUpdateDrawEntryIntentMutation,
  GeneratedUpdateDrawEntryIntentMutationVariables,
  GetDrawByProductIdDocument,
  UpdateDrawEntryIntentDocument,
} from '../../../types/generated';
import { captureError } from '../../../utils/capture';
import { shopifyGraphqlIdToAdminId } from '../../../utils/shopifyIds';
import { DrawGateway } from '../types';

/**
 * Note: Currently, the rate calculations are not set correctly in shopify
 *  for the staging environment which leads to inconsistent subtotal & shipping values returned by this service
 *  Eg.: Using a Swedish shipping address would result in a subtotal and shipping cost that includes tax
 *    but if you pick the Netherlands they won't include tax
 */
export class AvantArteDrawGateway implements DrawGateway {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private client: ApolloClient<unknown>) {}

  public async getDrawByProductId(productId: string) {
    try {
      const { data } = await this.client.query<
        GeneratedGetDrawByProductIdQuery,
        GeneratedGetDrawByProductIdQueryVariables
      >({
        query: GetDrawByProductIdDocument,
        variables: {
          productId: shopifyGraphqlIdToAdminId(productId),
        },
        fetchPolicy: 'no-cache',
      });
      return data.getDrawByProductId;
    } catch (err) {
      if (err instanceof Error) {
        captureError(err);
      }

      return void 0;
    }
  }

  public async createDrawEntryIntent(
    drawId: string,
    currencyCode: string,
    shippingAddressId?: string | null,
    paymentMethodId?: string | null,
    optionalItems?: GeneratedOrderItem[],
    cryptoPaymentRequested?: boolean | null,
  ) {
    try {
      const { data } = await this.client.mutate<
        GeneratedCreateDrawEntryIntentMutation,
        GeneratedCreateDrawEntryIntentMutationVariables
      >({
        mutation: CreateDrawEntryIntentDocument,
        variables: {
          drawId,
          currencyCode,
          shippingAddressId,
          paymentMethodId,
          optionalItems,
          cryptoPaymentRequested: Boolean(cryptoPaymentRequested),
        },
        fetchPolicy: 'no-cache',
      });
      return data?.createDrawEntryIntent ?? void 0;
    } catch (err) {
      if (err instanceof Error) {
        captureError(err);
      }

      return void 0;
    }
  }

  public async updateDrawEntryIntent(
    drawEntryIntentId: string,
    currencyCode: string,
    shippingAddressId?: string | null,
    paymentMethodId?: string | null,
    optionalItems?: GeneratedOrderItem[],
    cryptoPaymentRequested?: boolean | null,
  ) {
    try {
      const { data, errors } = await this.client.mutate<
        GeneratedUpdateDrawEntryIntentMutation,
        GeneratedUpdateDrawEntryIntentMutationVariables
      >({
        mutation: UpdateDrawEntryIntentDocument,
        variables: {
          id: drawEntryIntentId,
          currencyCode,
          shippingAddressId,
          paymentMethodId,
          optionalItems,
          cryptoPaymentRequested: Boolean(cryptoPaymentRequested),
        },
        fetchPolicy: 'no-cache',
      });

      if (data) return data.updateDrawEntryIntent ?? void 0;

      if (errors) throw new Error(errors[0].message);

      return void 0;
    } catch (err) {
      if (err instanceof Error) {
        captureError(err);
      }

      return void 0;
    }
  }

  public async getDrawEntryIntent(drawEntryIntentId: string) {
    try {
      const { data } = await this.client.query<
        GeneratedGetDrawEntryIntentQuery,
        GeneratedGetDrawEntryIntentQueryVariables
      >({
        query: GET_DRAW_ENTRY_INTENT,
        variables: {
          drawEntryIntentId,
        },
        fetchPolicy: 'no-cache',
      });
      return data?.getDrawEntryIntent ?? void 0;
    } catch (err) {
      if (err instanceof Error) {
        captureError(err);
      }

      return void 0;
    }
  }
}
